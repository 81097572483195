<template>
  <div id="app">
    <div id="nav">
      <a @click="logout">Logout</a>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
    methods: {
        logout: function () {
            this.$store.dispatch('authLogout')
            .then( () => {
                this.$router.push('/auth/login')
            })
        }
    },
}
</script>